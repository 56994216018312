// See core.css for variable references: https://atoms.azureedge.net/atoms/lib/latest/core.css

$sideSpace: var(--atoms-side-space); // 216px
$screenWidth: var(
  --atoms-screen-width
); //: calc(100vw - (2 * var(--atoms-side-space)));
$headerHeight: var(--atoms-header-height); // 6rem;

/* Atom Breakpoints */
$breakpointSmall: var(--atoms-breakpoint-small); // 672px;
$breakpointMedium: var(--atoms-breakpoint-medium); // 1056px;
$breakpointLarge: var(--atoms-breakpoint-large); // 1312px;
$breakpointXLarge: var(--atoms-breakpoint-x-large); // 1584px;

$xs: 375px;
$sm: 672px;
$md: 768px;
$lg: 1056px;
$xl: 1312px;
$xxl: 1584px;

$maxWidth: 1536px;
$baseSpacing: 16px;

// info
$info: #002c5a;
$infoLight: #d9e4ef;
// success
$success: #3d6712;
$successLight: #c1dea5;
// error
$error: #9e001b;
$errorLight: #f3ccd3;
// warning
$warning: #613c00;
$warningLight: #fad599;

$color25: #f2f6fa; // Note: manually corrected, as this color is very dark in design system, which doesn't make sense.
$color50: var(
  --atoms-basf-color-050
); // var(--atoms-basf-blue-dark-050);#E5EDF4
$color75: var(
  --atoms-basf-color-075
); // var(--atoms-basf-blue-dark-075); #D9E4EF
$color100: var(
  --atoms-basf-color-100
); // var(--atoms-basf-blue-dark-100); #CCDBEA
$color200: var(
  --atoms-basf-color-200
); // var(--atoms-basf-blue-dark-200); #99B7D5
$color300: var(
  --atoms-basf-color-300
); // var(--atoms-basf-blue-dark-300); #6692C0
$color400: var(
  --atoms-basf-color-400
); // var(--atoms-basf-blue-dark-400); #336EAB
$color500: var(
  --atoms-basf-color-500
); // var(--atoms-basf-blue-dark-500); #004A96
$color600: var(
  --atoms-basf-color-600
); // var(--atoms-basf-blue-dark-600); #003B78
$color700: var(
  --atoms-basf-color-700
); // var(--atoms-basf-blue-dark-700); #002C5A
$color800: var(
  --atoms-basf-color-800
); // var(--atoms-basf-blue-dark-800); #001E3C
$color900: var(
  --atoms-basf-color-900
); // var(--atoms-basf-blue-dark-900); #000f1e

$white: white;

$gray025: var(--atoms-basf-gray-025); // var(--atoms-basf-gray-025);
$gray050: var(--atoms-basf-gray-050); // var(--atoms-basf-gray-050); #F5F5F5
$gray075: var(--atoms-basf-gray-075); // var(--atoms-basf-gray-075); #F0F0F0;
$gray100: var(--atoms-basf-gray-100); // var(--atoms-basf-gray-100); #ECECEC;
$gray175: var(--atoms-basf-gray-175); // var(--atoms-basf-gray-175); #DDDDDD;
$gray200: var(--atoms-basf-gray-200); // var(--atoms-basf-gray-200); #D8D8D8;
$gray300: var(--atoms-basf-gray-300); // var(--atoms-basf-gray-300); #C5C5C5;
$gray400: var(--atoms-basf-gray-400); // var(--atoms-basf-gray-400); #B1B1B1;
$gray425: var(--atoms-basf-gray-425); // var(--atoms-basf-gray-425); #ADADAD;
$gray500: var(--atoms-basf-gray-500); // var(--atoms-basf-gray-500); #9E9E9E;
$gray600: var(--atoms-basf-gray-600); // var(--atoms-basf-gray-600); #7E7E7E;
$gray700: var(--atoms-basf-gray-700); // var(--atoms-basf-gray-700); #5F5F5F;
$gray800: var(--atoms-basf-gray-800); // var(--atoms-basf-gray-800); #3F3F3F;
$gray825: var(--atoms-basf-gray-825); // var(--atoms-basf-gray-825): #373737;
$gray900: var(--atoms-basf-gray-900); // var(--atoms-basf-gray-900); #202020;

$primaryColor: var(--atoms-primary-color); // var(--atoms-basf-blue-dark-500);
$secondaryColor: var(
  --atoms-secondary-color
); // var(--atoms-basf-blue-dark-375);
$activeBorderColor: var(--atoms-basf-color-500);
$inactiveBorderColor: var(--atoms-basf-color-100);
$hoverColor: var(--atoms-hover-color); // var(--atoms-basf-blue-dark-600);
$paleColor: var(--atoms-pale-color); // var(--atoms-basf-blue-dark-075);
$shadowColor: var(--atoms-shadow-color); // var(--atoms-basf-blue-dark-025);
$warnColor: var(--atoms-warn-color); // var(--atoms-basf-red-500);

$boxGray: var(--atoms-box-gray); // var(--atoms-basf-gray-075);
$lightGray: var(--atoms-light-gray); // var(--atoms-basf-gray-175);
$middleGray: var(--atoms-middle-gray); // var(--atoms-basf-gray-425);
$darkGray: $gray600; // #7E7E7E
$black: var(--atoms-black); //: var(--atoms-basf-gray-900);
