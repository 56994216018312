/* You can add global styles to this file, and also import other style files */
@import 'libs/ev/frontend/ui-library/src/lib/styles/light-green-styles.scss';

// @tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  margin: 0;
}

body {
  font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
}
