@use '../variables.scss' as vars;
@import './shepherd-styles.scss';

.ev-link {
  color: vars.$primaryColor;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

// Used for label & input wrappers
.ev-invalid {
  .ev-input {
    outline: 1px solid vars.$warnColor;
  }

  .ev-input-label {
    color: vars.$warnColor;
  }
}

.ev-input {
  // This is a dirty solution, to ensure, it has the same size as the atoms-input. Atoms-input shouldn't have a margin...
  // margin-top: 2px;
  // margin-bottom: 2px;
  border-radius: 0;
  height: 40px;
  border: 1px solid vars.$darkGray;
  padding: 0 16px;
  font-size: 16px;
  outline-width: 0;
  background-color: #fff;

  &.small {
    height: 32px;
    font-size: 14px;
  }

  // Deprecated in favor of .ev-invalid, should be removed
  &.invalid {
    outline: 1px solid vars.$warnColor;
  }
}

.ev-overlay {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100vw;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ev-input-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #373737;
  margin-bottom: 2px;
}

.ev-input-error {
  font-size: 12px;
  color: vars.$warnColor;
  margin-top: 2px;
  padding-left: 4px;
}

.ev-row:nth-of-type(even) {
  background-color: vars.$boxGray;
}

.ev-h1 {
  font-weight: 700;
  font-size: 42px;
  line-height: 50px;
  color: vars.$gray825;
}
.ev-h2 {
  font-weight: 700;
  font-size: 34px;
  line-height: 44px;
  color: vars.$gray825;
}

.ev-h3 {
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  color: vars.$gray825;
}
.ev-h4 {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: vars.$gray825;
}

.ev-highlight {
  background-color: vars.$gray050;
  padding: 16px 24px;

  @media (max-width: vars.$lg) {
    padding: 14px 22px;
  }

  @media (max-width: vars.$md) {
    padding: 12px 20px;
  }

  @media (max-width: vars.$sm) {
    padding: 10px 18px;
  }
}

.ev-table {
  .ev-table-header {
    display: grid;
    gap: 16px;
    padding: 8px 16px;
    background: vars.$gray825;
    color: #fff;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;

    @media (max-width: vars.$lg) {
      display: none;
    }

    // To style the columns
    // > * {
    // }
  }

  .ev-table-row {
    display: grid;
    gap: 16px;
    padding: 16px;

    &:nth-of-type(odd) {
      background-color: vars.$boxGray;
    }

    // Break into 3, 2 or 1 columns on smaller screens

    @media (max-width: vars.$lg) {
      grid-template-columns: repeat(3, 1fr);

      &:nth-child(2) {
        padding-top: 0;
      }

      @media (max-width: vars.$md) {
        grid-template-columns: repeat(2, 1fr);

        @media (max-width: vars.$sm) {
          grid-template-columns: repeat(1, 1fr);
          padding: 16px 8px;
        }
      }
    }
  }
}

.ev-hr {
  border-top: 1px solid vars.$gray175;
  border-bottom: 0;
}

.ev-error-outline {
  outline: 1px solid vars.$warnColor;
}

.ev-checkbox {
  height: 18px;
  width: 18px;
  margin-right: 8px;
}

.ev-secondary-button {
  background-color: vars.$white !important;
  color: vars.$primaryColor !important;
  border-color: vars.$primaryColor !important;

  &:hover {
    background-color: vars.$color300 !important;
    color: vars.$white !important;
  }
}

.ev-atom-success-chip .p-chip {
  background-color: vars.$success !important;
  color: vars.$white !important;
  font-weight: 700 !important;
  border-radius: 20px !important;
}

.ev-atom-normal-chip .p-chip {
  background-color: vars.$lightGray !important;
  color: vars.$black !important;
  font-weight: 700 !important;
  border-radius: 20px !important;
}

.ev-atom-error-chip .p-chip {
  background-color: vars.$error !important;
  color: vars.$white !important;
  font-weight: 700 !important;
  border-radius: 20px !important;
}

.ev-atom-error {
  color: vars.$error !important;
}

:root {
  --mat-sort-arrow-color: vars.$white !important;
}
